<script setup lang="ts">
import { PhHeart, PhHeartStraight } from '@phosphor-icons/vue';
import { useToast } from '@laam/ui/base';
import { useLoganStore } from '~/stores/logan';
import { storeToRefs } from 'pinia';
import { useWishlistStore } from '~/stores/wishlist';
import {
	useAddProductsToWishlist,
	useRemoveFromWishlist,
} from '~/data/wishlist';
import LazyImage from './LazyImage.vue';
import AddToList from './wishlist/AddToList.vue';
import type { ProductSchema } from '@laam/cms-shared';

const { $eventClient } = useNuxtApp();

interface ProductCardProps {
	productId: number;
	imageUrl: string;
	imageAlt: string;
	oos?: boolean;
}

const props = withDefaults(defineProps<ProductCardProps>(), {
	oos: false,
});

const { toast } = useToast();
const { favouritesMap, wishlists, favouriteProductsQueue } =
	storeToRefs(useWishlistStore());
const LoganStore = useLoganStore();
const { isSignedIn } = storeToRefs(LoganStore);
const { addProductsToWishlist, isPending } = useAddProductsToWishlist();
const { removeFromWishlist } = useRemoveFromWishlist();
const logan = useLoganStore();
const { pdpRedesignExperiment } = useStatsigExperiment();

const toggleButton = ref(false);

const isFavourited = ref(false);

const favouriteListId = computed(() => {
	return wishlists.value?.favouriteList.id;
});

const favouriteListName = computed(() => {
	return wishlists.value?.favouriteList.name;
});

const isFavouriteDelayed = ref(false);

const handleFavorite = (e?: Event) => {
	e?.preventDefault();
	if (!isSignedIn.value) {
		favouriteProductsQueue.value.push(props.productId);
		isFavouriteDelayed.value = true;
		LoganStore.setRequiresSignIn();
		return;
	}
	if (!favouriteListId.value) {
		toggleButton.value = !toggleButton.value;
		return;
	}
	addProductsToWishlist({
		logan_id: logan.loganId,
		wishlist_id: favouriteListId.value,
		product_shopify_ids: [props.productId],
	});

	if (!isFavourited.value) {
		isFavourited.value = true;
	}
	$eventClient.sendEvent('wishlist-add-product', {
		loganID: logan.loganId,
		productId: props.productId,
		wishlistID: favouriteListId.value,
		wishlistName: favouriteListName.value!,
		meta_data: {
			product: props as unknown as ProductSchema,
		},
	});

	toast({
		description: 'Item added to favourites',
		variant: 'info',
		class: 'py-md px-xl items-center gap-md lg:gap-xl',
		img: h(LazyImage, {
			src: props.imageUrl,
			alt: props.imageAlt,
			width: 25,
			class: 'w-full',
		}),
		action: h(
			'span',
			{
				class: /*tw*/ 'text-sm font-semibold hover:cursor-pointer',
				onClick: () => (sheetOpen.value = true),
			},
			['Add to list'],
		),
	});
};

const handleUnfavorite = () => {
	toggleButton.value = !toggleButton.value;
	if (!favouriteListId.value) {
		return;
	}

	removeFromWishlist({
		wishlist: favouriteListId.value,
		products: [props.productId],
		wishlistHandle: wishlists.value!.favouriteList.handle,
	});

	favouritesMap.value.delete(props.productId.toString());
	isFavourited.value = false;

	$eventClient.sendEvent('wishlist-remove-product', {
		loganID: logan.loganId,
		productId: props.productId,
		wishlistID: favouriteListId.value,
	});

	toast({
		description: 'Item removed from favourites',
		variant: 'info',
		class: 'py-md px-xl items-center gap-md lg:gap-xl',
		img: h(LazyImage, {
			src: props.imageUrl,
			alt: props.imageAlt,
			width: 25,
			class: 'w-full',
		}),
	});
};

watch(favouriteProductsQueue, (q) => {
	if (!q.length && isFavouriteDelayed.value) {
		isFavouriteDelayed.value = false;
		handleFavorite();
	}
});

onMounted(() => {
	if (isPending.value || favouritesMap.value.has(props.productId.toString())) {
		isFavourited.value = true;
	}
});

const sheetOpen = ref(false);

onMounted(() => {
	if (isPending.value || favouritesMap.value.has(props.productId.toString())) {
		isFavourited.value = true;
	}
});
</script>
<template>
	<div
		v-if="oos"
		class="cursor-pointer"
		@click.stop.prevent="
			(e) => (!isFavourited ? handleFavorite() : handleUnfavorite())
		"
	>
		<PhHeart
			:class="isFavourited && '!fill-error-600'"
			size="20"
			:weight="!isFavourited ? 'regular' : 'fill'"
		/>
	</div>
	<div
		v-else
		class="right-none group top-none p-[0.74rem] wishlist_trigger absolute bg-transparent"
		@click.stop.prevent="
			(e) => (!isFavourited ? handleFavorite() : handleUnfavorite())
		"
	>
		<PhHeartStraight
			:size="pdpRedesignExperiment?.pdpNewDesign ? 28 : 24"
			:weight="!isFavourited ? 'duotone' : 'fill'"
			class="group-active:scale-75 transition duration-150 ease-out"
			:class="{
				'fill-black [&>g>path:nth-last-child(1)]:fill-white': !isFavourited,
				'!fill-error-600': isFavourited,
			}"
		/>
	</div>

	<AddToList
		v-if="wishlists && sheetOpen"
		:title="'Add to list'"
		:products-to-move="[props.productId]"
		:open="sheetOpen"
		@closed="sheetOpen = false"
	>
		<!-- eslint-disable-next-line vue/no-lone-template -->
		<template></template>
	</AddToList>
</template>
